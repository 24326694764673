import './App.scss';
import { Link, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Placeholder from './pages/placeholder';
import Contact from './pages/contact';

function App() {
  return (
    <div className="App">
      <header className="header">
        <div className='headerTextContainer'>
          <h1>OSP Kwiatowe</h1>
          <h1>Strona Informacyjna</h1>
        </div>
        
        <div className='buttonContainer'>
          <Link to='' className='button'>Home</Link>
          <Link to='placeholder' className='button'>Placeholder</Link>
          <Link to='contact' className='button'>Kontakt</Link>
        </div>
      </header>
      <main className='content'>
        <Routes>
          <Route path='' element={<Home />} />
          <Route path='placeholder' element={<Placeholder/>} />
          <Route path='contact' element={ <Contact/>} />
        </Routes>
      </main>
      <footer className='footer'>

      </footer>
    </div>
  );
}

export default App;
